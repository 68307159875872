@import "../../@core/scss/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    background-color: transparent;
    font-weight: 100;
    color: lighten($color: $primary, $amount: 10);
    font-size: 16px;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: $primary;
      font-weight: bold;
      border-bottom: solid 3px;
    }
  }
}