.table {
  th {
    font-weight: bold;
  }

  th, td {
    border-bottom: 0;
    padding: 0.5px;
    padding-left: 5px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }

  td {
    text-align: left;
    vertical-align: top;
  }
}

@media print
{
  .table {
    th, td {
      font-size: 10px;
      padding: 4px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .table {
    th, td {
      font-size: 12px;
      padding: 4px;
    }
  }
}