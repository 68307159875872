@import "../../../../@core/scss/mixins";
@import "../../../../@core/scss/variables";

.container {
  display: flex;
  width: 100%;
  gap: 12px;
}

.details {
  height: max-content;
}
.cards {
  flex: 1;
  display: flex;
  gap: 12px;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.graphs {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    html,
    body {
      overflow: auto;
      overflow-y: auto;
    }
    margin-bottom: 250px;

    .cards {
      display: flex;
      flex-direction: column;
      margin-top: 26px;
      width: 100%;
    }
    .cardContent {
      display: flex;
      flex-direction: column;
      margin-top: 26px;

      margin-bottom: 10px;
    }
  }
}
