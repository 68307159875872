.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
  }

  .label {
    font-weight: bold;
    width: 80px;
  }

  .value {
    width: 150px;
  }
}

@media print
{
  .container {
    .label {
      width: 60px;
    }
  
    .value {
      width: 100px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    align-items: flex-start;
    margin-left: 5px;
  }
}