@import "../../../../../@core/scss/mixins";

.container {
  form {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    flex-direction: column;
    flex: 1;
    .patientContainer {
      display: flex;
      width: 100%;
      .newPatientContainer {
        display: flex;
        padding-left: 10px;
        margin-right: 10px;
        flex-direction: column;
        width: 100%;
        h2 {
          padding-left: 10px;
        }

        .divider {
          border: 0.6px solid #e3e3e3;
          width: 100%;
        }
        .infoData {
          display: flex;
          gap: 10px;
        }
      }
    }

    .dumInfo {
      display: flex;
      width: 100%;
      .dumContainer {
        display: flex;
        padding-left: 10px;
        margin-right: 10px;
        width: 100%;
        flex-direction: column;
        h2 {
          padding-left: 10px;
        }

        .divider {
          border: 0.6px solid #e3e3e3;
          width: 100%;
        }
      }

      .ultrasoundContainer {
        display: flex;
        padding-left: 10px;
        margin-right: 10px;
        width: 100%;
        flex-direction: column;

        h2 {
          padding-left: 10px;
        }

        .divider {
          border: 0.6px solid #e3e3e3;
          width: 100%;
        }
      }
    }

    .patientHealthContainer {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      margin-right: 10px;

      .patientInquiryContainer {
        gap: 10px;
        .patientInquiryInnerContainer {
          display: flex;
        }
      }

      .previousBirthsInfo {
        .patientSearchBox {
          width: 270px;
        }
        display: flex;
        flex-direction: column;
      }
    }

    .patientInsurance {
      display: flex;
    }

    .confirmButtonsContainer {
      display: flex;
      justify-content: space-between;
      width: 200px;
    }
  }

  .header {
    @include borderBottomLinear;
  }
}

.noPaddingLeft {
  padding-left: 0;
}

.noPaddingRight {
  padding-right: 0;
}

.btnAction {
  display: block;
  width: auto;
  padding: 10px 30px;
  font-size: 12px;
  height: auto;
  margin: 10px;

  &.secondary {
    background-color: white;
  }
}
@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    html,
    body {
      overflow: auto;
      overflow-y: auto;
    }
    margin-bottom: 250px;
    .dumInfo {
      flex-wrap: wrap;
    }
    .patientContainer {
      flex-wrap: wrap;
    }

    .patientHealthContainer {
      flex-wrap: wrap;
      .patientInquiryContainer {
        .patientInquiryInnerContainer {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .patientInsurance {
      flex-wrap: wrap;
    }
    .confirmButtonsContainer {
      display: flex;
      flex-direction: column;
      padding-left: 50px;
    }
  }
}
