.table {
  th {
    font-weight: bold;
  }

  th, td {
    border-bottom: 0;
    padding: 0.5px;
    padding-left: 10px;
    vertical-align: bottom;
  }

  .altUte, .apFetal {
    width: 40px;
  }

  .conduta {
    font-size: 12px;
  }

  .border {
    th, td {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }
}

@media print
{
  .table {
    th, td {
      font-size: 10px;
      padding: 4px;
    }

    .altUte, .apFetal {
      width: 20px;
    }
  
    .conduta {
      font-size: 8px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .table {
    th, td {
      font-size: 12px;
      padding: 4px;
    }

    .altUte, .apFetal {
      width: 20px;
    }
  
    .conduta {
      font-size: 10px;
    }
  }
}