@import "../../@core/scss/variables";
@import "../../@core/scss/mixins";

.card {
  background-color: white;
  border-radius: 16px;
  flex: 1;
  padding: 16px 24px;
  min-height: calc(100% - 2rem);
  overflow: auto;
  position: sticky;
  .header {
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    @include borderBottomLinear;

    strong {
      text-transform: uppercase !important;
      flex: 1;
    }

    div {
      color: $primary;
    }
  }
}

@include sm {
}
@media (max-width: 768px) {
  .card {
  }
}
