.Container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 !important;
}

.FileInfo {
    display: flex;
    justify-content: space-between;
}

.Preview {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
    }
}
.actions {
    display: flex;

    img {
        height: 30px;
    }
}
.Title {
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    color: #92a9ad;
}
.loader {
    display: flex;
    justify-content: center;
}
