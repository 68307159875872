.container {
  flex: 1;
  display: flex;
  justify-content: space-around;
  background-color: #ecf5df;
  border-top: 1px solid #1A535C;

  .qrcode {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 85px;
      height: 85px;
      object-fit: contain;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70px;
      height: 30px;
      object-fit: contain;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    div {
      display: flex;
      align-items: center;
      padding-left: 5px;

      span {
        padding-left: 5px;
      }
    }

    .icon {
      width: 18px;
      height: 18px;
      color: #1A535C;
      object-fit: contain;
    }
  }
}