.container {
  overflow-x: auto;
  width: 100%;

  td {
    padding: 3px;

    &.bold {
      font-weight: bold;

      .day {
        width: 27px;
        height: 27px;
        text-align: center;
      }
    }

    .rowValue {
      background-color: #f4f4f5;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      width: 25px;
      height: 25px;

      &.active {
        background-color: #f43f5e;
        border: 1px solid #f43f5e;
      }
    }
  }

  th:first-child {
    padding-right: 15px;
  }
}
