@import "../../../../../@core/scss/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $secondary;
  border-left: none;
  border-right: none;
  margin: 20px 0;
  padding: 20px 0;
  min-height: 72vh;

  p {
    text-align: center;
  }

  a {
    background-color: $primary;
    border-radius: 44px;
    color: $secondary;
    padding: 14px 18px;
    display: flex;
    align-items: center;
    outline: none;
    text-decoration: none;

    &:hover {
      color: darken($color: $secondary, $amount: 10);
    }

    svg {
      margin-right: 10px;
    }

    span {
      margin-top: -3px;
    }
  }

  h1 {
    color: $primary;
  }

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    width: 477px;
    height: 269px;

    border-radius: 25px;
    background: #eaf2f4;
    overflow: hidden;

    video {
      width: 100%;
      height: auto;
    }
  }

  .controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    bottom: 0;

    button {
      all: unset;
      cursor: pointer;
    }

    .pauseButton {
      opacity: 0;
    }
  }

  .video:hover {
    .pauseButton {
      opacity: 1;
    }
  }

  .highlight {
    color: $primary;
    text-align: center;
    font-weight: 700;

    margin-bottom: 2rem;
  }

  .doubts {
    text-align: center;
    font-weight: 700;
  }

  .link {
    all: unset;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      padding: 20px;
    }
    margin-top: 26px;
    html,
    body {
      overflow: auto;
      overflow-y: auto;
    }
    margin-bottom: 250px;

    .cards {
      display: flex;
      flex-direction: column;
      margin-top: 26px;
      width: 100%;
    }
    .cardContent {
      display: flex;
      flex-direction: column;
      margin-top: 26px;

      margin-bottom: 10px;
    }

    .video {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      height: 200px;

      border-radius: 25px;
      background: #eaf2f4;
      overflow: hidden;

      video {
        width: 100%;
        height: auto;
      }
    }

    .controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      bottom: 0;

      button {
        all: unset;
        cursor: pointer;
      }

      .pauseButton {
        opacity: 0;
      }
    }

    .video:hover {
      .pauseButton {
        opacity: 1;
      }
    }
  }
}
