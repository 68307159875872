@import "../../../@core/scss/mixins";
@import "../../../@core/scss/variables";

@media (max-width: 768px) {
  .infoContainer {
    height: 400px;
    gap: 2px;
  }
  .cardContainer {
    height: 150px;
  }
}
.card {
  display: flex;
  flex-direction: column;
  // border: 1px solid;
  // border-color: #1a535ccc;
  // border-radius: 7px;
  border-radius: 16px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(
      90deg,
      $primary,
      $secondary,
      $secondary,
      $secondary,
      $secondary,
      $secondary,
      $secondary,
      $primary
    );
  background-origin: border-box;
  background-clip: content-box, border-box;

  .content {
    padding: 20px;
    width: max-content;
  }
  .header {
    padding: 10px;
  }
}
.profileContainer {
  display: flex;
  gap: 10px;
}

.plan {
  display: flex;
  justify-content: space-between;
}
.planValue {
  display: flex;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
