@import "@core/scss/variables";
@import "@core/scss/mixins";

.containerExames {
  th, td {
    border: 0
  }

  .head {
    font-size: 14;
    font-weight: 400;
    color: #1A535C;
  }

  .text {
    font-size: 12;
    color: #1A535C;
  }

  .starIcon {
    color: #1A535C;
    cursor: pointer;
  }
}