@import "./@core/scss/mixins";

.page {
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  .sidebar {
    overflow-y: auto;
    height: 100vh;
  }

  .container {
    overflow-y: auto;
    width: 100%;
    height: 100vh;
  }
}

@mixin sidebarAbsolute {
  .page {
    flex-direction: column;

    .sidebar {
      height: auto;
    }
  }
}

@include sm {
  @include sidebarAbsolute;
}

@include md {
  @include sidebarAbsolute;
}
@media (max-width: 768px) {
  .page {
    place-items: start;
  }
}
