.container {
    border: 2px dashed #92a9ad;
    border-radius: 16px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    p {
        color: #376970;
        margin: 0;
    }

    :focus {
        outline: none;
    }
}

.content {
    text-align: center;
    width: 400px;
    height: 40px;
    margin: 0 56px 1rem;
    border-radius: 4px;
    padding: 20px;

    img {
        height: 42px 1rem;
    }
}

.buttonSelector {
    background-color: transparent !important;
    padding: 1rem;
    height: 36px;
    border: 1px solid rgba(26, 83, 92, 0.8);
    color: rgba(26, 83, 92, 0.8) !important;
    font-size: 14px !important;
    font-weight: 700;
}