.container {
  display: flex;
  flex-wrap: wrap;
  max-width: 30vw;

  .loadingContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
      margin: 0px 10px !important;
    }
  }
}
