@import "../../@core/scss/variables";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $primary;
    font-weight: bold;
  }
}
