@import "../../@core/scss/variables";

.container {
  padding: 5px;
  display: block;

  .tag {
    border: none;
    font-size: 14px;
    padding: 4px 12px;
    line-height: 15px;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 15px;
    border-radius: 18px;
    margin: 5px;
    display: flex;
    justify-content: center;

    &.primary {
      background: $primary-1;
      box-shadow: 0px 1px 0px darken($color: $primary, $amount: 20);
      color: $secondary;
    }

    &.secondary {
      background: $secondary;
      box-shadow: 0px 1px 0px darken($color: $secondary, $amount: 20);
      color: $primary;
    }

    .btnClose {
      cursor: pointer;
      padding-left: 5px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
