
@import "../../@core/scss/variables";

.ldsRing {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

.ldsRing2 {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  margin-right: 30px;
}

.ldsRing2 div {
  display: block;
  position: absolute;
  margin-right: 30px;
  width: 14px;
  height: 14px;
  border: 2px solid $primary;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary  transparent transparent transparent;
}

.ldsRing2 div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing2 div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing2 div:nth-child(3) {
  animation-delay: -0.15s;
}

.secondaryLoaderContainer{
  display: flex;
}


@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
