@import "../../../@core/scss/variables";
@import "../../../@core/scss/mixins";

.forgetPasswordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $primary;

  h1 {
    text-transform: uppercase;
    font-weight: bold;
  }

  .containerEmail {
    width: 270px;
  }

  .containerButtons {
    margin-top: 10px;
    width: 270px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      height: 1rem;
      font-size: 15px;
    }

    button:nth-child(1) {
      margin-right: 10px;
    }
  }
}

@include xl {
  .forgetPasswordContainer {
    h1 {
      font-size: 2vw;
    }
  }
}

@include md {
  .forgetPasswordContainer {
    h1 {
      font-size: 3.5vw;
    }
  }
}

@include sm {
  .forgetPasswordContainer {
    h1 {
      font-size: 5.5vw;
    }
  }
}