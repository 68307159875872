@import "../../../../@core/scss/variables";

.container {
  flex: 1;
  display: flex;
  justify-content: space-around;

  .historico {
    display: flex;
    flex-direction: column;

    span {
      font-weight: bold;
    }

    .tags {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
      }
    }
  }

  .tag {
    display: flex;
    justify-content: center;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
    margin-bottom: 3px;
    font-size: 12px;
    border-radius: 18px;
    background: $secondary;
    box-shadow: 0px 1px 0px darken($color: $secondary, $amount: 20);
    color: $primary;
  }
}

@media print
{
  .container {
    .historico {
      span {
        font-size: 10px;
        padding-bottom: 5px;
      }
    }

    .tag {
      padding-left: 2px;
      padding-right: 2px;
      margin-right: 2px;
      margin-bottom: 3px;
      font-size: 8px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    .historico {
      span {
        font-size: 12px;
        padding-bottom: 5px;
      }
    }

    .tag {
      padding-left: 2px;
      padding-right: 2px;
      margin-right: 2px;
      margin-bottom: 3px;
      font-size: 10px;
    }
  }
}