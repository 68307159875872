@import "../../../@core/scss/variables";
@import "../../../@core/scss/mixins";

.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  color: $primary;

  .info {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 2rem;

    h2 {
      font-size: 2rem !important;
      font-weight: 400;
      margin: 0;
    }

    h1 {
      margin: 0;
      font-size: 32px;
    }

    p {
      font-weight: 275;
      color: #27272a;
      text-transform: none;
      font-size: 18px;
    }
  }

  label {
    font-weight: 500;
  }

  .typeCargo{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .containerInput {
    margin-top: 20px;
    width: 320px;
    text-align: center;
  }

  .uf_crm {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;
    margin: 10px;
    margin-top: 25px;
    border: 1px solid #508b96;
    border-radius: 40px;
    background-color: #fff;

    label {
      display: none;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      border: none;
    }

    div > div:first-child {
      border-right: 1px solid $border-color;
      border-radius: 40px;
    }

    p {
      position: absolute;
      margin: 0;
      left: 0;
      top: -22px;

      font-size: 14px;
      font-weight: 400;
      color: #3e6c75;
    }
  }

  .containerButton {
    width: 250px;
    text-align: center;

    hr {
      width: auto;
      margin: 14px;
      border: 2px;
      padding: 1px;
      background-image: linear-gradient(white, white), linear-gradient(90deg, $secondary, #1a535c87, $secondary);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    button {
      height: 1rem;
      margin-top: 10px;
      font-size: 15px;
    }
  }

  .divide {
    margin-top: 20px;
    width: 20%;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    hr {
      width: -webkit-fill-available;
      margin: 14px;
      border: 2px;
      padding: 1px;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }

    .one {
      background-image: linear-gradient(white, white), linear-gradient(90deg, $secondary, #1a535c87);
    }

    .two {
      background-image: linear-gradient(white, white), linear-gradient(90deg, #1a535c87, $secondary);
    }
  }

  .googleButton {
    margin-top: 30px;
  }
}

@include xl {
  .loginContainer {
    h1 {
      font-size: 2vw;
    }
  }
}

@include md {
  .loginContainer {
    h1 {
      font-size: 3.5vw;
    }
  }
}

@include sm {
  .loginContainer {
    h1 {
      font-size: 5.5vw;
    }
  }
}
