@import "../../../../../../@core/scss/variables";
@import "../../../../../../@core/scss/mixins";
.icon {
  height: 20px;
  width: 20px;
  margin-left: auto;
}

  .titleSection {
    font-size: 17px;
    font-weight: bold;
    color: #27272A;
    margin-left: auto;

  }
  .spacerHeader {
    height: 40px;
  }

  .line {
    width: 100%;
    height: 1px;
    margin-top: 5px;
    @include borderBottomLinear;
  }

  .rowInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
  .rowCheckbox {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .rowCheckboxHistorico {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: -50px;
  }
  .width30{
    width: 30%;
    margin: 10px;
  }
  .width60{
    width: 60%;
  }
  .cardBody {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
  
      &.spaceBetween {
        justify-content: space-between;
      }
    }
  }
  .btnAction {
    display: block;
    width: auto;
    padding: 10px 100px;
    font-size: 12px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  
    &.secondary {
      background-color: white;
    }
  }








 
  .spacer {
    height: 16px;
  }

  .container {
    border: 1px solid #FADDEE;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 16px;
    .icon {
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-left: auto;
      cursor: pointer;
    }
    .imageContainer {
      height: 60px;
      width:  60px;
      object-fit: contain;
    }
    .containerText {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .title {
        font-size: 17px;
        font-weight: bold;
        color: #1A535C;
      }
      .subTitle {
        font-size: 14;
        font-weight: 400;
        color: #1A535C;
      }
      .desc {
        font-size: 13;
        font-weight: 300;
        color: #707070;
      }
    }
  }

   .headerConsulta {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    width: 513px;
    
    .containerBack{
      flex-direction: row;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: fit-content;
      .containerIcon {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        background-color: #D1DDDE;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .text {
        font-size: 10px;
        color: #1A535C;
      }

    }
    .containerMid {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      .imageContainer {
        height: 60px;
        width:  60px;
        object-fit: contain;
      }
      .containerText {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .title {
          font-size: 17px;
          font-weight: bold;
          color: #1A535C;
        }
        .subTitle {
          font-size: 14;
          font-weight: 400;
          color: #1A535C;
        }
        .desc {
          font-size: 13;
          font-weight: 300;
          color: #707070;
        }
      }
    }
  }

