.container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .page {
    height: 1120px;
    width: 100%;
    display: flex;
    /* to centre page on screen*/
    margin-left: auto;
    margin-right: auto;

    .dividerVertical {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc(50% - 1px);

      div.carteirinha,
      div.historico,
      div.consultas,
      div.destaques,
      div.examesLaboratoriais,
      div.examesImagem,
      div.vacinas,
      div.qrcode {
        width: 100%;
        border-left: 1px solid #1a535c;
      }

      .carteirinha,
      .historico,
      .consultas {
        border-left: 0px !important;
      }

      .carteirinha {
        height: 135px;
      }

      .historico {
        height: 135px;
      }

      .consultas {
        flex: 1;
      }

      .destaques {
        height: 270px;
      }

      .examesLaboratoriais {
        flex: 1;
      }

      .examesImagem {
        height: 195px;
      }

      .vacinas {
        height: 135px;
      }

      .qrcode {
        height: 90px;
      }
    }
  }
}

.btnPrint {
  position: absolute;
  left: 10px;
  top: 10px;
}

@media print {
  @page {
    margin: 0 !important;
  }

  .page {
    font-size: 10px;
  }

    .btnPrint {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .page {
        font-size: 12px;
        height: 100%;
        flex-direction: column;

        .dividerVertical {
            width: 100% !important;
        }
    }
}
