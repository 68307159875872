@import "../../../../../../@core/scss/variables";

.select {
  border: none;
  color: $primary;

  &:focus {
    outline: none;
  }
}
