.container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

html,
body,
#root {
  font-family: "Sarabun", sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
