.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .row {
    flex: 1;
    display: flex;

    .destaque {
      flex: 1;
      display: flex;
      align-items: center;
      border-right: 1px solid #1A535C;
      border-bottom: 1px solid #1A535C;
      font-size: 12px;

      .consulta {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 2px;
        padding-right: 2px;
      }

      .exame {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 2px;
        padding-right: 2px;

        .resultado {
          text-align: center;
        }
      }
    }
  }
}

@media print
{
  .container {
    .row {
      .destaque {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    .row {
      .destaque {
        font-size: 10px;
      }
    }
  }
}