.table {
  th {
    font-weight: bold;
  }

  th,
  td {
    border-bottom: 0;
    padding: 0px;
    padding-left: 5px;
  }

  td {
    text-align: left;
    vertical-align: top;
  }

  .exame {
    width: 150px;
  }

  .data {
    width: 100px;
  }
}

@media print {
  .table {
    th,
    td {
      font-size: 10px;
      padding: 2px;
    }

    .exame {
      width: 100px;
    }

    .data {
      width: 65px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .table {
    th, td {
      font-size: 12px;
      padding: 2px;
    }

    .exame {
      width: 100px;
    }
  
    .data {
      width: 65px;
    }
  }
}
