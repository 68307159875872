.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .title {
        align-items: center;
        justify-content: center;
        height: 30px;
        font-weight: bold;
        color: #1A535C;
        display: flex;
        background-color: #ecf5df;
        border-bottom: 1px solid #1A535C;
    }
}