@import "../../@core/scss/variables";

.container {
  .box {
    margin: 0;
    position: absolute;
    z-index: 99;
    margin-top: -30px;
    margin-left: -30px;
    width: 195px;

    li {
      list-style: none;

      .tag {
        cursor: pointer;
        display: block;
        width: 165px;
        margin: 0;
      }
    }
  }

  .children {
    margin: -26px 0px 0px 10px;
  }
}
