@import "../../@core/scss/mixins";
@import "../../@core/scss/variables";

.container {
  display: flex;
  margin-top: 26px;
  flex-flow: wrap;

  .main {
    padding: 10px;
    padding-bottom: 17px;
    margin-bottom: -15px;
    background-color: $secondary;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-right: 12px;
    font-size: 16px;
    font-weight: bold;
    max-width: 10vw;
    height: 20px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px !important;
    }
  }

  .body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: $secondary;
    margin-top: 15px;
    border-top-left-radius: 25px;

    .content {
      background-color: white;
      margin: 24px;
      border-radius: 15px;
    }

    .transparentContent {
      background-color: transparent !important;
    }
  }
}

@mixin commonsResponsive {
  .container {
    .main {
      border-top-left-radius: 0;
    }
  }
}

@include sm {
  @include commonsResponsive;
}

@include md {
  @include commonsResponsive;
}
