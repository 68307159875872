@import "../../@core/scss/mixins";
@import "../../@core/scss/variables";

@mixin smallScreenSidebar {
  .hide {
    display: none;
  }

  .show {
    display: block !important;
    z-index: 1;
  }

  .show {
    position: fixed;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: scroll;

    .sidebarContainer {
      background-color: white;
      max-width: 250px;
    }
  }

  .btnToggleSidebar {
    height: 50px !important;
    width: 50px !important;
    border-radius: 5px !important;
    padding: 0 !important;
    margin: 20px !important;
    display: block !important;
  }

  .btnCloseSidebar {
    right: -71px;
    position: absolute;
    top: -20px;
    border-radius: 0 !important;
  }
}

@include sm {
  @include smallScreenSidebar;
}

@include md {
  @include smallScreenSidebar;
}

.btnToggleSidebar {
  display: none;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  height: 100%;
  margin-right: 20px;
  padding: 32px;

  .item {
    width: 100%;
    margin-bottom: 16px;
    padding: 16px;
    padding-top: 0;
    @include borderBottomLinear;

    a {
      margin: 5px 0;
    }

    .active {
      &::before {
        width: 3px;
        height: 18px;
        content: "";
        margin-left: -25px;
        background-color: $primary;
        position: absolute;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }

  .userData {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    padding: 6px;

    .imageProfileUrl {
      display: flex;
      border-radius: 16px;
      align-items: center;
      border: 1px solid transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(
          90deg,
          $primary,
          $secondary,
          $secondary,
          $secondary,
          $secondary,
          $secondary,
          $secondary,
          $primary
        );
      background-origin: border-box;
      background-clip: content-box, border-box;

      img {
        max-width: 120px;
        padding: 10px 0;
        margin-left: -10px;
      }
    }

    .welcomeContainer {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      .welcome {
        font-weight: bold;
        color: $primary;
      }

      .username {
        font-weight: 300;
      }
    }
    .contentUpgrade {
      display: flex;
      text-decoration: none;
      color: black;
      max-height: 30px;
      height: 30px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-size: 290px 100px;
      background-color: #fff;
      background-origin: border-box;
      background-clip: content-box, border-box;

      Button {
        padding: 0px 8px;
        width: 76px;
        height: 24px;
        background: linear-gradient(
          90deg,
          rgba(234, 242, 244, 0.2) 1.01%,
          rgba(183, 126, 42, 0.2) 52.7%,
          rgba(234, 242, 244, 0.2) 100.25%
        );
        border: 1px solid #b77e2a;
        border-radius: 16px;

        p {
          color: #b77e2a !important;
          font-size: 12px;
          text-transform: uppercase;
        }
      }

      .welcome {
        font-size: 11px;
        margin-left: 3px;
        margin-right: 6px;
      }
    }
  }

  .fastItems {
    display: flex;
    flex-direction: column;

    a {
      background-color: $primary;
      border-radius: 44px;
      color: $secondary;
      padding: 12px 18px;
      display: flex;
      align-items: center;
      outline: none;
      text-decoration: none;
      &:hover {
        color: darken($color: $secondary, $amount: 10);
      }

      svg {
        margin-right: 10px;
      }

      span {
        margin-top: -3px;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;

    a {
      background-color: $secondary;
      border-radius: 44px;
      color: $primary;
      padding: 14px 18px;
      display: flex;
      align-items: center;
      outline: none;
      text-decoration: none;

      &:hover {
        color: lighten($color: $primary, $amount: 20);
      }

      svg {
        margin-right: 10px;
      }

      span {
        margin-top: -3px;
      }
    }
  }

  .configurationItems {
    display: flex;
    flex-direction: column;

    a,
    .navigateItem {
      padding: 14px 18px;
      display: flex;
      align-items: center;
      outline: none;
      text-decoration: none;
      color: $primary;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;

      &:hover {
        color: lighten($color: $primary, $amount: 20);
      }
    }

    svg {
      margin-right: 10px;
    }

    span {
      margin-top: -3px;
    }
  }
}
@media (max-width: 768px) {
  .sidebarContainer {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .btnToggleSidebar {
    display: none;
  }
}

.card {
  border: solid 1px #cacaca;
  border-radius: 30px;
  padding: 20px;
  margin-bottom: 20px;

  p {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #999;
    margin: 0px;
  }

  div {
    display: flex;
    align-items: end;
    justify-content: space-around;
  }

  button {
    color: $primary;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      color: lighten($color: $primary, $amount: 20);
    }
  }
}

.upgradeButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .patientsLeft {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
